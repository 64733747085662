import React, { useState } from 'react';
import heroHinge from '../images/hero_hinge.webp';
import fireCad from '../images/firecad.png';
import balsaCad from '../images/balsa_cad.webp';
import guitarCad from '../images/guitar_cad.webp';
import heroSuspension from '../images/hero_suspension.webp';
import sigmaExploded from '../images/sigma_exploded.png';
import ATT from '../images/ATT.webp';
import dataSci from '../images/data_science.webp';
import MCA from '../images/mcacert.webp';
import FutureHacks from '../images/future_hacks_event_card.webp';
import LifeHacks from '../images/life_hacks_event_card.webp';
import ISEF from '../images/ISEF.webp';
import MCACAD from '../images/mca.png';
import MCADiagram from '../images/diagrammca.png';
import NWPEE from '../images/NWPEE.webp';
import Rocket from '../images/rocket_cad.webp';
import Yale from '../images/yalecert.webp';
import Navigate from '../images/NavigateUCSD.png';
import NWPEEEC from '../images/NWPEEextra.png';
import Whirl from '../images/Whirl.png';
import one from '../images/108.png';
import Regen from '../images/Regen.png';
import actuator from '../images/Actuator.png';
import sole from '../images/solenoid.png';
import daq from '../images/daq.png';
import sdcta from '../images/sdcta.png';
import ysc from '../images/YSC.png';
import tesla from '../images/tesla.webp';
import rtx from '../images/rtx.png';
import nasa from '../images/nasalogo.png';
import tse from '../images/tselogo.png';
import seventh from '../images/seventh_logo.png';
import robot from '../images/trirob.png';
import multiscale from '../images/multiscale_logo.png';
import esw from '../images/esw_logo.png';
import xdhacks from '../images/xdhacks_logo.png';
import seds from '../images/seds.png';

const Projects = () => {
  const [expandedExperience, setExpandedExperience] = useState(null);
  const [expandedProject, setExpandedProject] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const skills = {
    programmingLanguages: [
      'Java', 'JavaScript', 'React Native', 'TypeScript', 'C', 'C++', 'Robotics Framework', 
      'Python', 'HTML/CSS', 'SQL', 'Perl', 'R', 'ARM Assembly'
    ],
    cadAndSimulation: [
      'Solidworks', 'Fusion 360', 'FreeCAD', 'Simulink', 'AutoCAD', 'Siemens NX'
    ],
    softwareAndTechnologies: [
      'MATLAB', 'Confluence', 'TortoiseSVN', 'Wireshark', 'JIRA', 'Tableau', 'Microsoft Office', 
      'Arduino', 'CAN', 'Git', 'Kubernetes', 'Pandas', 'Linux', 'Windows', 'Raspberry Pi', 'Microsoft Azure'
    ]
  };

  const certifications = [
    { title: 'Computer Science + Mathematics, Engineering Mechanics', issuer: 'University of California, San Diego' },
  ];

  const experiences = [
    {
      title: 'Full Stack Software Engineer, Research and Development Engineer, Computer Vision Engineer',
      company: 'Tesla',
      duration: 'Aug 2024 – present',
      link: 'https://www.tesla.com',
      image: tesla
    },
    {
      title: 'Avionics Software Engineering Intern',
      company: 'RTX (Collins Aerospace)',
      duration: 'Jun 2023 – Sep 2023',
      description: 'Developed test suite for AFDX Filter Driver software using Python, Wireshark, Robot Framework.',
      link: 'https://www.rtx.com',
      image: rtx
    },
    {
      title: 'Software/Propulsion Engineer + Business Team',
      company: 'SEDS@UCSD',
      duration: 'Sep 2021 – present',
      description: 'Optimized cooling channels via MATLAB, used Arduino to have motor control systems, and engineered data processing backend. Also Worked on obtaining sponsorships, deliverables, and grants to fund rocket manufacturing, and collaborated with the Halya Team on funding and purchasing parts for engines, structures, and plumbing.',
      link: 'https://www.sedsucsd.org',
      image: seds
    },
    {
      title: 'Developer & Product Manager',
      company: 'Triton Software Engineering',
      duration: 'Sep 2021 – Mar 2023',
      description: 'Developed scaling strategy, contributed to mobile app UI, and created data visualization website for multiple non-profits such as Y Stem & Chess and San Diego County Taxpayers Association.',
      link: 'https://tse.ucsd.edu',
      image: tse
    },
    {
      title: 'Mechanical Engineer',
      company: 'Triton Robotics',
      duration: 'Mar 2021 – Mar 2023',
      description: 'Designed components, repaired electronics, and optimized motor efficiency.',
      link: 'https://tritonrobotics.ucsd.edu',
      image: robot
    },
    {
      title: 'Undergraduate Researcher',
      company: 'Multiscale Flow Physics Laboratory',
      duration: 'Jan 2022 – Present',
      description: 'Worked on optimizing/studying Fire Whirl Behavior via custom made apparatus.',
      link: 'https://asanchez.ucsd.edu/',
      image: multiscale
    },
    {
      title: 'Sensor Research Engineer',
      company: 'NASA Proposal Writing and Evaluation Experience Academy Program',
      duration: 'May 2022 – Aug 2022',
      description: 'Worked on understanding variety of sensors and implementation into the mapping of the environment without Lidar for proposal based on NASA requirements.',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    },
    {
      title: 'Computer Hardware Engineer',
      company: 'NASA Mission Concept Academy Program',
      duration: 'Sep 2022 – Dec 2022',
      description: 'Worked on implementing Communication Data Handling System within Preliminary Design Review and ensured PDR was approved based on NASA Taxonomy',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    },
    {
      title: 'Student Transportation Representative',
      company: 'Seventh College Student Council',
      duration: 'Sep 2021 – Jun 2022',
      description: 'Served as liaison between the Seventh College Student Council and the Student Transportation Advisory Committee, working to initiate programs to improve campus travel for Seventh College students.',
      link: 'https://seventh.ucsd.edu/student-life/Involvement-opportunities/student-council.html',
      image: seventh
    },
    {
      title: 'Information Management Committee & Community Affairs Committee',
      company: 'Engineers for a Sustainable World @ UCSD',
      duration: 'Sep 2021 – Jun 2022',
      description: 'Tracked member involvement, gathered feedback for improvement, and assisted the VP of Information Management in documenting events.',
      link: 'https://eswtritons.wordpress.com/',
      image: esw
    },
    {
      title: 'Executive - Logistics - Outreach Director of XdHacks Mini LA Division',
      company: 'XdHacks Mini LA',
      duration: 'Jun 2018 – Jun 2022',
      description: 'Hosted Life Hacks, securing 10 sponsors and 9 partners, writing the logistics report, moderating workshops, and creating an effective marketing strategy; also hosted Future Hacks, securing 11 sponsors and 11 partners, and helped create an ambassador program.',
      link: 'https://mini.xdhacks.com/la/',
      image: xdhacks
    }
  ];

  const csProjects = [
    { 
      title: 'Fire Whirl Fuel Consumption Programs', 
      description: 'Developed Java program for fire whirl data analysis.',
      details: 'Created program for data input, statistics, and heatmap visualization.',
      link: 'https://github.com/akashnaren/Fire-Whirl-Research',
      image: Whirl
    },
    { 
      title: 'Sensor Analysis and Diagram - Proposal', 
      description: 'Worked on terrain identification through motion pattern recognition.',
      details: 'Developed concept for navigation without visual components.',
      link: 'https://drive.google.com/file/d/14K27CWZ6--VPD4KafsgW-Lm2CraexVv-/view',
      image: NWPEEEC
    },
    { 
      title: 'Communication Data Handling System - Preliminary Design Review', 
      description: 'Contributed to asteroid tracking mission concept.',
      details: 'Researched communication system for transmitting asteroid data.',
      link: 'https://drive.google.com/file/d/1yuL5K4KWOEQkNw3uv4jcXuIYfIhT_VL3/view',
      image: MCACAD
    },
    { 
      title: 'Arduino/C++ Actuator/LED Control', 
      description: 'Manipulating Linear Actuator Control for Thrust/Launch Control',
      details: 'Program that allows control of physical actuator and voltage control of LEDs',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: actuator
    },
    { 
      title: 'Regenerative Cooling MATLAB Program', 
      description: 'MATLAB Program made to take input parameters to show best optimized engine thermals.',
      details: 'The MATLAB simulation models and analyzes the behavior of a rocket\'s trajectory and performance parameters, providing insights into dynamics, stability, and performance under various conditions to inform design decisions. Using MATLAB for its robust numerical computing and data visualization capabilities, the simulation initializes parameters and constants, defines the equations of motion, employs numerical integration techniques, and iterates over time steps to update the rocket\'s state. The results are then visualized and analyzed to optimize the rocket\'s design and ensure successful deployment, similar to the precision required in RTE systems and TDK for reliable aerospace applications.',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: Regen
    },
    { 
      title: ' Data Acquisition - Firmware/Web Based', 
      description: 'Acquire data and control mechanical components.',
      details: 'The program uses a web-based GUI via an Arduino, utilizing Python, Flask, HTML, CSS, and JavaScript for serial communication and user interaction. The firmware, written in C++ with the Arduino framework, employs FreeRTOS for task management, handling power monitoring, solenoid control, and CAN communication for real-time operations and feedback.',
      link: 'https://github.com',
      image: sole
    },
    { 
      title: 'Python Data Acquisition', 
      description: 'Data Analysis/Manipulation from ADC chips for real time data monitoring',
      details: 'The project aims to provide a web-based interface to display real-time data from ADC chips connected to an Arduino via serial communication. Using Python, HTML, CSS, and JavaScript, the system reads data from the ADC chips, logs it to a CSV file, and displays it on a web interface for real-time monitoring and visualization. ',
      link: 'https://github.com',
      image: daq
    },
    { 
      title: 'NavigateUCSD', 
      description: 'Implemented mapping functionality for campus navigation.',
      details: 'Used Azure Maps and React for enhanced navigation features.',
      link: 'https://navigateucsd.com/',
      image: Navigate
    },
    { 
      title: 'Flight Delays/Cancellations Analysis', 
      description: 'Analyzed flight data using Python and Pandas.',
      details: 'Distilled complex data into actionable insights.',
      link: 'https://github.com/COGS108/Group134_FA23/blob/master/recent-2.ipynb',
      image: one
    },
    { 
      title: 'Chess Mobile Application', 
      description: 'Aided in complex development and product management with client.',
      details: 'The YSC Mobile App Project aims to develop a mobile application that provides an accessible platform for underserved children to learn chess with mentor assistance. Utilizing Python for the backend, HTML, CSS, and JavaScript for the frontend, and React Native for mobile development, the app offers functionalities like account management, real-time gameplay, mentorship sessions, and lesson progression. The project involves detailed planning, client interaction, team coordination, and continuous improvement to ensure a high-quality, user-friendly experience for students and mentors.',
      link: 'https://github.com/TritonSE/YSC-Mobile-Application',
      image: ysc
    },
    { 
      title: 'Web App utilizing Tableau for San Diego Taxpayers Association', 
      description: 'Aided in complex development and product management with client.',
      details: 'The SDCTA Data Visualization Website Project aims to create an intuitive, interactive platform to enhance user experience and provide accessible statistical information, addressing current limitations. The project involves detailed planning, feature development, and close collaboration with the client and development team to ensure successful implementation and post-launch support. The focus is on robust features like account creation, data visualization, and user feedback to increase engagement and subscription rates.',
      link: 'https://github.com/TritonSE/SDCTA-Data-Visualization-Website',
      image: sdcta
    },
    { 
      title: 'XdHacks Mini Life Hacks', 
      description: 'Acted as Outreach/Logistics Director and helped create Life Hacks Hackathon',
      details: 'With many countries like America falling into a doctor shortage crisis, medical workers now have to work harder than ever to adequately provide treatment to an ever growing population. As the world becomes more and more digitized, virtual assets that aid in lightening the workload to increase the efficiency of their job is a promising solution.',
      link: 'https://mini.xdhacks.com/la/life_hacks',
      image: LifeHacks
    },
    { 
      title: 'XdHacks Mini Future Hacks', 
      description: 'Acted as Executive Director of the Los Angeles Division, created Future Hacks Hackathon',
      details: 'With the 2021 NASA Mars Rover Mission, engineers and scientists have been able to gather more data on Mars, particularly on its environment. As the world races towards the day when the first humans are sent to Mars, transitioning from an Earth-like environment to Mars will be daunting. Virtual assets, however, could be vital in making this daunting process far more efficient, smooth, and manageable.',
      link: 'https://mini.xdhacks.com/la/future_hacks',
      image: FutureHacks
    },
    { 
      title: 'XdHacks Mini ISEF Regeneron', 
      description: 'Aided in hosting event in person',
      details: 'Event allowed me to promote organization, host workshops, activities, and network.',
      link: 'https://mini.xdhacks.com/isef/',
      image: ISEF
    },
  ];

  const mechProjects = [
    { 
      title: 'Hero Robot Design', 
      description: 'Designed suspension and hinge components using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroHinge
    },
    { 
      title: 'Balsa Plane Design', 
      description: 'Designed plane using Solidworks.',
      link: 'https://drive.google.com/file/d/1sLYrEc3xf4-CYzFRQlrBNHqSdl3OtcDl/view?usp=drive_link',
      image: balsaCad
    },
    { 
      title: 'Guitar CAD Design', 
      description: 'Designed Guitar using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/19r8-ncs2huf93GgdjPnBdkkJxP9u7OAR',
      image: guitarCad
    },
    { 
      title: 'Fire Whirl Apparatus', 
      description: 'Designed experimental plates for fire whirl studies using Solidworks and AUTOCAD.',
      link: 'https://drive.google.com/drive/u/2/folders/1TBw-k0n8ihaZWIJXB0zGc9gJjjk1pG00',
      image: fireCad
    },
    { 
      title: 'Structural Analysis', 
      description: 'Conducted analysis and modeled acceleration profiles for motor optimization.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroSuspension
    },
    { 
      title: 'Asteroid Tracking Mission', 
      description: 'Contributed to PDR for accurate asteroid trajectory tracking.',
      link: 'https://drive.google.com/file/d/1yuL5K4KWOEQkNw3uv4jcXuIYfIhT_VL3/view',
      image: sigmaExploded
    },
    { 
      title: 'Rocket CAD Design', 
      description: 'Part of MAE 2 Coursework designing an introductory style rocket through CAD.',
      link: 'https://drive.google.com/file/d/1Td4VCpzgpKOtRhGUKJcnsPHpFlgMhWWA/view?usp=sharing',
      image: Rocket
    },
  ];

  return (
    <div className="min-h-screen bg-[#0b080d] text-white p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Resume
        </h1>

        {/* Education Section */}
        <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Education
        </h2>
        <div className="mb-12">
          <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-8 hover:border-blue-500/50 transition-all duration-300">
            {certifications.map((cert, index) => (
              <div key={index} className="relative">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                  <div className="flex-1">
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                      {cert.title}
                    </h3>
                    <div className="flex items-center gap-2 mt-2">
                      <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      <h4 className="text-lg text-gray-300">{cert.issuer}</h4>
                    </div>
                  </div>
                  
                  <div className="hidden md:block">
                    <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 backdrop-blur-sm p-3">
                      <svg className="w-full h-full text-blue-400/50" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 21l9-5-9-5-9 5 9 5z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        
        {/* Skills Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Skills & Technologies
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Programming Languages */}
            <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <svg className="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                </svg>
                Programming Languages
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.programmingLanguages.map((skill, index) => (
                  <span 
                    key={index} 
                    className="px-3 py-1.5 bg-blue-500/10 text-blue-400 rounded-full text-sm font-medium hover:bg-blue-500/20 transition-colors"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>

            {/* CAD & Simulation */}
            <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                CAD & Simulation
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.cadAndSimulation.map((skill, index) => (
                  <span 
                    key={index} 
                    className="px-3 py-1.5 bg-purple-500/10 text-purple-400 rounded-full text-sm font-medium hover:bg-purple-500/20 transition-colors"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>

            {/* Software & Technologies */}
            <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <svg className="w-5 h-5 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                </svg>
                Software & Technologies
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.softwareAndTechnologies.map((skill, index) => (
                  <span 
                    key={index} 
                    className="px-3 py-1.5 bg-pink-500/10 text-pink-400 rounded-full text-sm font-medium hover:bg-pink-500/20 transition-colors"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Experience Section */}
        <h2 className="text-2xl mb-4">Professional Experience</h2>
        <p className="text-sm text-gray-400 mb-2">Scroll horizontally and hover to expand</p>
        <div className="mb-12 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
          <div className="flex gap-6 pb-6" style={{ minWidth: 'max-content' }}>
            {experiences.map((exp, index) => (
              <div 
                key={index} 
                className="bg-gray-800 border border-gray-700 rounded-xl p-6 shadow-lg transition-all duration-300 hover:w-[28rem] w-72 group relative overflow-hidden"
              >
                {/* Background Pattern */}
                <div className="absolute inset-0 opacity-5 bg-gradient-to-br from-blue-400 to-purple-600 pointer-events-none" />
                
                {/* Company Logo */}
                <div className="absolute top-6 right-6 w-12 h-12 rounded-lg overflow-hidden bg-gray-900/50 p-2 backdrop-blur-sm">
                  <img 
                    src={exp.image} 
                    alt={`${exp.company} logo`} 
                    className="w-full h-full object-contain"
                  />
                </div>

                {/* Position Titles */}
                <div className="mb-6 pr-14">
                  {exp.title.split(', ').map((position, posIndex) => (
                    <div 
                      key={posIndex} 
                      className={`${posIndex > 0 ? 'mt-3 pt-3 border-t border-gray-700' : ''}`}
                    >
                      <h3 className="text-lg font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                        {position}
                      </h3>
                    </div>
                  ))}
                </div>

                {/* Company and Duration */}
                <div className="space-y-2 mb-4">
                  <h4 className="text-xl text-white font-semibold">{exp.company}</h4>
                  <p className="text-gray-400 text-sm tracking-wide">{exp.duration}</p>
                </div>

                {/* Description - Shown on Hover */}
                {exp.description && (
                  <div className="transform transition-all duration-300 opacity-0 group-hover:opacity-100 h-0 group-hover:h-auto">
                    <div className="border-t border-gray-700 pt-4 mt-4">
                      <p className="text-gray-300 text-sm leading-relaxed">
                        {exp.description}
                      </p>
                    </div>
                  </div>
                )}

                {/* Link */}
                <a 
                  href={exp.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors mt-4 group-hover:mt-6"
                >
                  <span className="text-sm font-medium">Visit Website</span>
                  <svg className="w-4 h-4 transform transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </a>
              </div>
            ))}
          </div>
        </div>
        {/* Computer Science Projects Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Projects
          </h2>
          <p className="text-sm text-gray-400 mb-2">Scroll horizontally and hover to expand</p>
        </section>
          <div className="mb-12 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
            <div className="flex gap-6 pb-6" style={{ minWidth: 'max-content' }}>
              {csProjects.map((project, index) => (
                <div 
                  key={index} 
                  className="bg-gray-800 border border-gray-700 rounded-xl p-6 shadow-xl transition-all duration-300 hover:w-[32rem] w-80 group relative overflow-hidden"
                >
                  {/* Gradient Background */}
                  <div className="absolute inset-0 opacity-5 bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 pointer-events-none" />
                  
                  {/* Content Container */}
                  <div className="relative z-10">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-6">
                      <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                        {project.title}
                      </h3>
                      <a 
                        href={project.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="p-2 rounded-lg bg-gray-700/50 hover:bg-gray-700 transition-colors"
                      >
                        <svg className="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </a>
                    </div>

                    {/* Description */}
                    <p className="text-gray-300 text-sm leading-relaxed mb-4">
                      {project.description}
                    </p>

                  {/* Details on Hover */}
                  <div className="transform transition-all duration-300 opacity-0 group-hover:opacity-100 h-0 group-hover:h-auto">
                    <div className="border-t border-gray-700 pt-4 mt-4 space-y-4">
                      <p className="text-gray-300 text-sm leading-relaxed">
                        {project.details}
                      </p>
                      
                      {/* Project Image */}
                      <div className="relative h-48 rounded-lg overflow-hidden bg-gray-900">
                        <img 
                          src={project.image} 
                          alt={project.title} 
                          className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/20 to-transparent opacity-60" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Mechanical Engineering Projects Section */}
        <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Mechanical Engineering Projects
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {mechProjects.map((project, index) => (
            <div 
              key={index} 
              className="group bg-gray-800 border border-gray-700 rounded-xl shadow-xl overflow-hidden hover:border-blue-500/50 transition-all duration-300 relative"
            >
              {/* Image Section */}
              <div className="relative h-64 overflow-hidden">
                <img 
                  src={project.image} 
                  alt={project.title} 
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/20 to-transparent" />
                
                {/* Floating Title */}
                <div className="absolute bottom-0 left-0 right-0 p-6">
                  <h3 className="text-xl font-bold text-white mb-2 group-hover:text-blue-400 transition-colors">
                    {project.title}
                  </h3>
                  <p className="text-gray-300 text-sm">
                    {project.description}
                  </p>
                </div>
              </div>

              {/* Action Button */}
              <div className="p-4 bg-gray-800/80 backdrop-blur-sm border-t border-gray-700">
                <a 
                  href={project.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-semibold py-3 px-4 rounded-lg inline-flex items-center justify-center gap-2 transition-all duration-300"
                >
                  <span>View Project</span>
                  <svg className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Certificates Section */}
        <section className="mt-16 mb-16">
          <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Certifications & Achievements
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* NASA NWPEE */}
            <div className="group bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-lg bg-blue-500/10 flex items-center justify-center">
                  <img src={nasa} alt="NASA" className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-white">NASA NWPEE</h3>
                  <p className="text-sm text-gray-400">2022</p>
                </div>
              </div>
              <p className="text-gray-300 text-sm mb-4">
                Certification for completing the NASA L'SPACE NWPEE Academy program in proposal writing and evaluation.
              </p>
              <button 
                onClick={() => window.open(NWPEE, '_blank')}
                className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
              >
                <span className="text-sm">View Certificate</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>

            {/* NASA MCA */}
            <div className="group bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-lg bg-purple-500/10 flex items-center justify-center">
                  <img src={nasa} alt="NASA" className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-white">NASA MCA</h3>
                  <p className="text-sm text-gray-400">2022</p>
                </div>
              </div>
              <p className="text-gray-300 text-sm mb-4">
                Certification for completing the NASA L'SPACE Mission Concept Academy program.
              </p>
              <button 
                onClick={() => window.open(MCA, '_blank')}
                className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
              >
                <span className="text-sm">View Certificate</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>

            {/* Yale Psychology Certificate */}
            <div className="group bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-lg bg-blue-500/10 flex items-center justify-center">
                  <img src={Yale} alt="Yale" className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-white">Yale Psychology</h3>
                  <p className="text-sm text-gray-400">2020</p>
                </div>
              </div>
              <p className="text-gray-300 text-sm mb-4">
                Introduction to Psychology course completion certificate from Yale University through Coursera.
              </p>
              <button 
                onClick={() => window.open(Yale, '_blank')}
                className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
              >
                <span className="text-sm">View Certificate</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>

            {/* IBM Python Certificate */}
            <div className="group bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-lg bg-blue-500/10 flex items-center justify-center">
                  <img src={dataSci} alt="IBM" className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-white">IBM Python Data Science</h3>
                  <p className="text-sm text-gray-400">2021</p>
                </div>
              </div>
              <p className="text-gray-300 text-sm mb-4">
                Python 101 for Data Science certification from IBM.
              </p>
              <button 
                onClick={() => window.open(dataSci, '_blank')}
                className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
              >
                <span className="text-sm">View Certificate</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>

            {/* AT&T Summer Learning Academy */}
            <div className="group bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-2xl p-6 hover:border-blue-500/50 transition-all duration-300">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-lg bg-blue-500/10 flex items-center justify-center">
                  <img src={ATT} alt="AT&T" className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-white">AT&T Summer Learning Academy</h3>
                  <p className="text-sm text-gray-400">2021</p>
                </div>
              </div>
              <p className="text-gray-300 text-sm mb-4">
                Completed AT&T Summer Learning Academy program focusing on business, technology, and personal development.
              </p>
              <button 
                onClick={() => window.open(ATT, '_blank')}
                className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
              >
                <span className="text-sm">View Certificate</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Projects;
